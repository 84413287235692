.grayBg {
    background-color: rgb(29, 29, 29);
}

* {
    padding: 0;
    margin: 0;
}

.flexCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mainBtn {
    padding: 10px 50px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

.mainBtn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.box {
    width: 50%;
    height: 50%;
    background:linear-gradient(0deg, rgba(29, 29, 29, 0.65), rgba(29, 29, 29, 0.65)), url("../../assets/capa.png");
    background-position: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    gap: 10px
}

@media only screen and (max-width: 800px) {
    .box {
        width: 90%;
    } 
}

.error {
    border-color: red;
}

.info {
    cursor: pointer;
}

.modal {
    position: absolute;
    width: 90vw;
    height: 90vh;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 20px;
    left: 5vw
}

.button {
    cursor: pointer;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 35px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 35px;
    height: 35px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }